<template>
  <div class="agent-profile py-2">
    <div class="text-center text-warning py-1">{{ datetime }}</div>
    <div class="d-flex justify-content-between px-2 py-1">
      <span>Name</span>
      <span>{{ UserInfo.firstName }} {{ UserInfo.lastName }}</span>
    </div>
    <div class="d-flex justify-content-between px-2 py-1">
      <span>Username</span>
      <span>{{ UserInfo.username }}</span>
    </div>
    <div class="d-flex justify-content-between px-2 py-1">
      <span>Balance</span>
      <span>{{ Balance }}</span>
    </div>
    <div class="d-flex justify-content-between px-2 py-1">
      <span>Member</span>
      <span>{{ Member | amountFormat(0) }}</span>
    </div>
    <div class="d-flex justify-content-between px-2 py-1">
      <span>Online</span>
      <span>{{ Online | amountFormat(0, '0') }}</span>
    </div>
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import AppConfig from '@/configs/app.config.js'

export default {
  name: 'AgentProfile',
  data() {
    return {
      datetime: moment().add(543, 'y').format('D MMMM YYYY, HH:mm:ss'),
      TimeInterval: null,
      AccountInterval: null,
      isShowAlertMessage: false
    }
  },
  computed: {
    UserInfo() {
      return this.$store.state.userProfile
    },
    Balance() {
      return this.$store.state.account.balance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    Member() {
      return this.$store.state.account.member
    },
    Online() {
      return this.$store.state.account.online
    }
  },
  mounted() {
    this.$store.dispatch('reloadAccount')
    this.AccountInterval = setInterval(()=>{
      console.log('reload account')
      const isLoggedIn = localStorage.getItem(AppConfig.key.LoggedIn)
      console.log('isLoggedIn', isLoggedIn)
      if(isLoggedIn) {
        this.$store.dispatch('reloadAccount')
      }else{
        clearInterval(this.AccountInterval)
      }
    }, 60000)

    this.TimeInterval = setInterval(()=>{
      this.datetime = moment().add(543, 'y').format('D MMMM YYYY, HH:mm:ss')
    }, 1000)
  },
  beforeDestroy() {
    console.log('beforeDestroy!')
    clearInterval(this.AccountInterval)
    clearInterval(this.TimeInterval)
  }
}
</script>
<style lang="scss" scoped>
.agent-profile {
  font-size: 90%;
  border-bottom: 2px solid #fff;
  color: #fff;
}
</style>
